import {
  RecaptchaVerifier,
  signOut,
  updateProfile,
  deleteUser,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import LogRocket from "logrocket";
import { useEffect, useRef } from "react";
import { auth } from "./firebase";

//invisible recaptcha verifier
export function reCaptchaVerifier() {
  if (window.Cypress || process.env.REACT_APP_STAGING === "local") {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  return new RecaptchaVerifier(
    "recaptcha-placeholder",
    {
      size: "invisible",
    },
    auth
  );
}

export async function otpRequest(phoneNumber) {
  const appVerifier = window.reCaptchaVerifier;
  const authProvider = new PhoneAuthProvider(auth);
  const verificationId = await authProvider.verifyPhoneNumber(
    phoneNumber,
    appVerifier
  );
  return verificationId;
}

export function getCurrentUser() {
  return auth.currentUser;
}

export async function otpValidate(loginOtp, verificationId) {
  const authCredentials = PhoneAuthProvider.credential(
    verificationId,
    loginOtp
  );
  return authCredentials;
}

export async function signInWithAuthCredentials(authCredentials) {
  const response = await signInWithCredential(auth, authCredentials);
  return response;
}

//SET USER DISPLAY NAME
export async function setDisplayName(clinicName) {
  await updateProfile(auth.currentUser, {
    displayName: clinicName,
  });
}

//signup using auth credentials
export async function signUpWithAuthCredentials(authCredentials, name) {
  const response = await signInWithCredential(auth, authCredentials);
  if (response.user.displayName === null) {
    const displayName = {
      clinicStaff: name,
      doctor: null,
      patient: null,
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName),
    });
  } else {
    let otherDisplayNames;
    try {
      otherDisplayNames = JSON.parse(response.user.displayName);
    } catch (error) {
      otherDisplayNames = {};
    }
    const displayName = {
      ...otherDisplayNames,
      clinicStaff: name,
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayName),
    });
  }
  return response;
}

//auth subscriber
export function useAuthSubscriber(onAuthStateChange, onAccessToken) {
  const isAuth = useRef(false);

  // for isAuth listener
  useEffect(() => {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const accessToken = await user.getIdToken();
        onAccessToken(accessToken)

        if (isAuth.current !== true) {
          isAuth.current = true;
          LogRocket.identify(user.uid, {
            phoneNumber: user.phoneNumber,
          });
          onAuthStateChange(isAuth.current);
        }
      } else if (!user && isAuth.current === true) {
        logout();
        isAuth.current = false;
        onAuthStateChange(isAuth.current);
      }
    });
    // eslint-disable-next-line
  }, []);
  return isAuth.current;
}

//logout subscriber
export const logout = async () => {
  await signOut(auth);
};

export async function deleteUserAccount() {
  await deleteUser(auth.currentUser);
}
