import React, { useRef, useEffect, useState } from "react";
import "./SnackBar.css";
import { CloseIcon, ErrorIcon, SuccessIcon } from "../../Assets/assets";

function SnackBar(props) {
  const firstSnackBar = useRef(null);
  const secondSnackBar = useRef(null);

  const firstSnackBarStatusRef = useRef("DONE");
  const secondSnackBarStatusRef = useRef("DONE");

  const [firstSnackBarStatus, setFirstSnackBarStatus] = useState("DONE");
  const [secondSnackBarStatus, setSecondSnackBarStatus] = useState("DONE");

  const setTimeoutInterval = 750;

  const [snackBarsContents, setSnackBarsContents] = useState({
    firstSnackBar: {
      type: "",
      message: "",
    },
    secondSnackBar: {
      type: "",
      message: "",
    },
  });

  const snackBarsContentsRef = useRef({
    firstSnackBar: {
      type: "",
      message: "",
    },
    secondSnackBar: {
      type: "",
      message: "",
    },
  });
  // set snack bar content
  useEffect(() => {
    if (
      typeof props.message === "string" &&
      props.message.length !== 0 &&
      snackBarsContentsRef.current.firstSnackBar.message === "" &&
      !firstSnackBar.current.className.includes("show")
    ) {
      if (firstSnackBarStatusRef.current === "DONE") {
        snackBarsContentsRef.current = {
          ...snackBarsContentsRef.current,
          firstSnackBar: {
            type: props.type,
            message: props.message,
          },
        };
        setSnackBarsContents({ ...snackBarsContentsRef.current });
        showFirstSnackBar();
      }
    } else if (
      typeof props.message === "string" &&
      props.message.length !== 0 &&
      snackBarsContentsRef.current.secondSnackBar.message === "" &&
      !secondSnackBar.current.className.includes("show")
    ) {
      if (secondSnackBarStatusRef.current === "DONE") {
        snackBarsContentsRef.current = {
          ...snackBarsContentsRef.current,
          secondSnackBar: {
            type: props.type,
            message: props.message,
          },
        };
        setSnackBarsContents({ ...snackBarsContentsRef.current });
        showSecondSnackBar();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.message, firstSnackBarStatus, secondSnackBarStatus]);

  const showFirstSnackBar = () => {
    firstSnackBarStatusRef.current = "PROCESSING";
    if (secondSnackBar.current.className.includes("show")) {
      hideSecondSnackBar();
    }
    firstSnackBar.current.className += " show";
    setTimeout(() => {
      if (
        firstSnackBar.current &&
        firstSnackBar.current.className.includes("show")
      ) {
        hideFirstSnackBar();
      }
      firstSnackBarStatusRef.current = "DONE";
      setFirstSnackBarStatus("DONE");
      window.location.reload();
    }, setTimeoutInterval * 2.5);
  };

  const hideFirstSnackBar = () => {
    if (
      typeof props.status?.message === "string" &&
      typeof props.removeStatus === "function"
    ) {
      props.removeStatus();
      window.location.reload();
    }
    firstSnackBar.current.className = firstSnackBar.current.className.replace(
      "show",
      "fadeOut"
    );
    snackBarsContentsRef.current = {
      ...snackBarsContentsRef.current,
      firstSnackBar: {
        type: "",
        message: "",
      },
    };
    setTimeout(() => {
      setSnackBarsContents({ ...snackBarsContentsRef.current });
    }, setTimeoutInterval / 2);
    setTimeout(() => {
      if (firstSnackBar.current && firstSnackBar.current.className) {
        firstSnackBar.current.className =
          firstSnackBar.current.className &&
          firstSnackBar.current.className.replace("fadeOut", "");
      }
    }, setTimeoutInterval);
  };

  const showSecondSnackBar = () => {
    secondSnackBarStatusRef.current = "PROCESSING";
    if (firstSnackBar.current.className.includes("show")) {
      hideFirstSnackBar();
    }
    secondSnackBar.current.className += " show";
    setTimeout(() => {
      if (secondSnackBar.current.className.includes("show")) {
        hideSecondSnackBar();
      }
      secondSnackBarStatusRef.current = "DONE";
      setSecondSnackBarStatus("DONE");
      window.location.reload();
    }, setTimeoutInterval * 2.5);
  };

  const hideSecondSnackBar = () => {
    if (
      typeof props.status.message === "string" &&
      typeof props.removeStatus === "function"
    ) {
      props.removeStatus();
      window.location.reload();
    }
    secondSnackBar.current.className = secondSnackBar.current.className.replace(
      "show",
      "fadeOut"
    );
    snackBarsContentsRef.current = {
      ...snackBarsContentsRef.current,
      secondSnackBar: {
        type: "",
        message: "",
      },
    };
    setTimeout(() => {
      setSnackBarsContents({ ...snackBarsContentsRef.current });
    }, setTimeoutInterval / 2);
    setTimeout(() => {
      secondSnackBar.current.className =
        secondSnackBar.current.className.replace("fadeOut", "");
    }, setTimeoutInterval);
  };

  return (
    <React.Fragment>
      <section
        data-cy="snackBar"
        className="snackbar width-80-percentage"
        ref={firstSnackBar}
      >
        <div className="box-shadow-default font-color-secondary flex-align-items-center font-size-small padding-medium inherit-parent-width font-family-RHD-medium background-white flex-justify-content-space-between text-align-center font-size-small border-radius-default">
          {snackBarsContents.firstSnackBar.type === "success" && (
            <SuccessIcon />
          )}
          {snackBarsContents.firstSnackBar.type === "error" && <ErrorIcon />}
          <div className="max-width-75-percentage height-fit-content text-overflow-ellipsis">
            {snackBarsContents.firstSnackBar.message}
          </div>

          <div
            style={{ height: "14px" }}
            onClick={hideFirstSnackBar}
            data-cy="snackBarCloseIcon"
          >
            <CloseIcon height="14" width="16" color="red" />
          </div>
        </div>
      </section>

      <section className="snackbar width-80-percentage" ref={secondSnackBar}>
        <div className="box-shadow-default font-color-secondary flex-align-items-center font-size-small padding-medium inherit-parent-width font-family-RHD-medium background-white flex-justify-content-space-between text-align-center font-size-small border-radius-default">
          {snackBarsContents.secondSnackBar.type === "success" ? (
            <SuccessIcon />
          ) : (
            <ErrorIcon />
          )}
          <div className="max-width-75-percentage height-fit-content text-overflow-ellipsis">
            {snackBarsContents.secondSnackBar.message}
          </div>

          <div
            style={{ height: "14px" }}
            onClick={hideSecondSnackBar}
            data-cy="snackBarCloseIcon"
          >
            <CloseIcon height="14" width="16" color="red" />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default SnackBar;
