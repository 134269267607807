import { api } from "../utils/constants";

export async function raiseComplaint(complaintData, accessToken) {
  const response = await fetch(`${api.baseUrl}/complaint`, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "x-auth-token": `Bearer ${accessToken}`,
    },
    body: complaintData,
  });
  const result = await response.json();
  if (result.error) {
    throw result.error;
  }
  return result;
}
