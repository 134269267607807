import React, { useRef, useState, useEffect } from "react";
import { ArrowIcon, ErrorIcon, ValidIcon } from "../../Assets/assets";
import "./PhoneNumberBox.css";

function PhoneNumberBox(props) {
  const [value, setValue] = useState("");
  const [error, setError] = useState();
  const label = useRef(null);
  const inputRef = useRef(null);
  const previousLoadingState = useRef(false);
  const [complete, setComplete] = useState(false);

  //set complete status
  useEffect(() => {
    if (previousLoadingState.current === true && props.loading === false) {
      setComplete(true);
    } else {
      if (complete) setComplete(false);
    }
    previousLoadingState.current = props.loading;
    // eslint-disable-next-line
  }, [props.loading]);

  const handleTextInput = (event) => {
    setValue(event.target.value);
    props.onChange && props.onChange(event);
  };

  // set onchange value
  useEffect(() => {
    setValue(props.value);
    setComplete(false);
  }, [props.value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validators = (value) => {
    //return if props.value length is "0"
    let isValidInput = true;
    if (!value) {
      isValidInput = false;
      props.isValidInput && props.isValidInput(isValidInput);
      return;
    }
    if (String(value).length === 0) {
      return;
    }

    if (props.validation) {
      if (props.validation(value)?.status === false) {
        isValidInput = false;
        if (props.validation(value)?.message === error) {
          return;
        }
        setError(props.validation(value).message);
      } else {
        if (error) setError(null);
      }
    } else if (
      props.name &&
      props.name.toLowerCase().includes("year") &&
      props.name !== "yearOfExperience"
    ) {
      if (
        !(
          typeof value === "string" &&
          value.trim().length !== 0 &&
          value.length === 4
        )
      ) {
        isValidInput = false;
        if (error === "Please enter a valid Year") return;
        setError("Please enter a valid Year");
      } else {
        if (error) setError(null);
      }
    } else {
      switch (props.name) {
        case "phoneNumber":
          if (value.length > 3 && value.length < 10) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (value.length < 10) {
            isValidInput = false;
            props.isValidInput && props.isValidInput(isValidInput);
            return;
          } else if (isNaN(value)) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (value.length > 10) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (!/^[0-9+]+$/.test(value)) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (value.trim().length > 10) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else {
            if (error) {
              setError(null);
            }
          }
          break;

        default:
          if (error) setError(null);
          break;
      }
    }
    if (props.isValidInput) {
      props.isValidInput(isValidInput);
    }

    if (isValidInput) {
      inputRef.current.classList.remove("error");
    } else {
      inputRef.current.classList.add("error");
    }
  };

  //for validation
  useEffect(() => {
    validators(value);
    // eslint-disable-next-line
  }, [value, props.validation]);
  useEffect(() => {
    if (props.forceFocus) {
      label.current && label.current.classList.add("active");
    }
    // eslint-disable-next-line
  }, [props.forceFocus]);

  const InputSuffixIcon = () => {
    if (props.loading === true) {
      return (
        <div
          className="circular-loader"
          data-cy={`${props.name}-input-box-loader-icon`}
        />
      );
    } else if (typeof error === "string") {
      return (
        <div
          data-cy={`${props.name}-input-box-error-icon`}
          className={`height-width-1em ${props.iconClassNames}`}
        >
          <ErrorIcon />
        </div>
      );
    } else if (value && `${value}`.trim().length !== 0 && complete === true) {
      return (
        <div
          data-cy={`${props.name}-input-box-complete-icon`}
          className={`height-width-1em ${props.iconClassNames}`}
        >
          <ValidIcon />
        </div>
      );
    } else {
      return null;
    }
  };

  const InputInfo = () => {
    if (typeof error === "string" && error !== "") {
      return (
        <p className="font-size-smaller padding-top-small font-color-red">
          {error}
        </p>
      );
    } else if (typeof props.notes === "string") {
      return (
        <p
          className={` ${
            props.inputNotesClassName
              ? props.inputNotesClassName
              : "font-size-smaller font-color-secondary padding-top-small"
          } `}
        >
          {props.notes}
        </p>
      );
    } else {
      return <p className="font-size-smaller">&nbsp;</p>;
    }
  };

  return (
    <div
      className={`inherit-parent-width  ${
        props.className ? props.className : ``
      }`}
    >
      <div
        className={` position-relative display-flex flex-align-items-end height-48px inherit-parent-width  ${
          props.disabled === true ? " background-color-grey " : ""
        } ${
          props.size === "half"
            ? "min-width-138px"
            : props.size === "small"
            ? "min-width-120px"
            : "min-width-250px"
        }`}
      >
        <div className="flex-center-children-horizontally font-size-medium font-family-RHD-medium inherit-parent-width">
          <div className="inherit-parent-width">
            <div className="display-flex">
              <div
                disabled={props.countryInfoClickDisable}
                onClick={
                  !props.countryInfoClickDisable
                    ? props.countryInfoClick
                    : () => {}
                }
                style={{
                  borderTop: "0px",
                  borderLeft: "0px",
                  borderRight: "0px",
                  borderBottom: "1px",
                  borderStyle: "solid",
                }}
                className={`padding-top-large padding-bottom-default display-flex cursor-pointer`}
              >
                <div className="width-fit-content padding-right-default display-flex flex-justify-content-center flex-align-items-center background-color-grey">
                  {props.selectedCountry.dial_code}
                </div>
                <div className="width-fit-content background-color-grey">
                  {props.selectedCountry.flag}
                </div>
                <div
                  style={{
                    transform: "rotate(90deg)",
                    width: "22px",
                    paddingTop: "4px",
                  }}
                  className="background-color-grey display-flex flex-justify-content-center"
                >
                  <ArrowIcon />
                </div>
              </div>

              <input
                id={props.name}
                data-cy={
                  props["data-cy"]
                    ? props["data-cy"]
                    : `${props.name}-input-box`
                }
                readOnly={props.disabled}
                ref={inputRef}
                autoFocus={props.autoFocus}
                // defaultValue={props.defaultValue}
                value={props.value}
                className={`input-box background-transparent max-width-100-percentage inherit-parent-width font-family-RHD-medium font-size-medium padding-top-large padding-bottom-default display-block  input-box-border-bottom-default input-box-disabled-padding-left-5px border-radius-0 ${
                  typeof props.buttonTile === "string"
                    ? "padding-right-4p7-em"
                    : ""
                } ${
                  (props.type === "date" || props.type === "time") &&
                  "padding-right-0"
                } ${
                  props.disabled === true
                    ? "font-color-secondary disabled"
                    : "font-color-secondary"
                }`}
                autoComplete={props.autoComplete}
                min={props.minDate}
                max={
                  props.type === "date"
                    ? props.maxDate
                      ? props.maxDate
                      : new Date().toISOString().split("T")[0]
                    : null
                }
                onFocus={(event) => {
                  if (props.forceFocus) {
                    return (
                      label.current && label.current.classList.add("active")
                    );
                  }
                  if (props.disabled === true) {
                    return;
                  }
                  if (label.current) {
                    label.current && label.current.classList.add("active");
                  }
                  if (!!inputRef.current) {
                    inputRef.current &&
                      inputRef.current.classList.add("active");
                  }
                }}
                onBlur={(event) => {
                  if (props.forceFocus) {
                    return;
                  }
                  if (
                    !event.currentTarget.value &&
                    props.type !== "date" &&
                    props.type !== "time"
                  )
                    label.current && label.current.classList.remove("active");
                  inputRef.current &&
                    inputRef.current.classList.remove("active");
                  if (event.target.value.trim().length === 0) {
                    setError(null);
                    inputRef.current.classList.remove("error");
                  }
                  if (typeof props.onOutOfFocus === "function") {
                    props.onOutOfFocus(event);
                  }
                }}
                type={
                  props.type === "number"
                    ? null
                    : props.type
                    ? props.type
                    : null
                }
                name={props.name}
                onChange={handleTextInput}
                required={props.required === true ? true : false}
                inputMode={
                  props.type === "number"
                    ? "numeric"
                    : props.inputMode
                    ? props.PhoneNumberBox
                    : null
                }
              />
            </div>

            <span className="input-box-border-bottom" />
            <label
              htmlFor={props.name}
              ref={label}
              className={` ${
                props.removeResponsive ? "" : "adaptive-font-size"
              } input-box-label font-color-secondary  position-absolute font-family-RHD-medium font-size-medium ${
                props.labelClassName ? props.labelClassName : ""
              } ${
                inputRef.current === document.activeElement ||
                props.value ||
                props.defaultValue ||
                (props.type === "number" && parseInt(props.value) > 0
                  ? true
                  : false) ||
                props.type === "time" ||
                props.type === "date" ||
                props.alwaysActive ||
                props.autoFocus === true
                  ? "active"
                  : ""
              } ${props.disabled ? " disabled " : ""}
							`}
            >
              {props.label}
            </label>
            {typeof props.inputSuffixPlaceHolder === "string" && (
              <div className="position-absolute right-0 bottom-0 padding-bottom-default display-flex flex-align-items-center">
                <label
                  className={` font-color-tertiary font-size-medium font-family-RHD-regular padding-left-small `}
                >
                  {props.inputSuffixPlaceHolder}
                </label>
              </div>
            )}
          </div>
          <div
            className={` position-absolute  flex-align-items-center flex-justify-content-center min-width-1em absolute-center-self-vertically
					 	${props.type === "date" || props.type === "time" ? " right-1p5em" : " right-0"}
					 `}
          >
            {typeof props.inputSuffixPlaceHolder !== "string" && (
              <InputSuffixIcon />
            )}
            {typeof props.buttonTile === "string" && (
              <button
                key={"otp-button"}
                id={props.buttonTileId}
                type={props.buttonType}
                data-cy={
                  props["button-data-cy"]
                    ? props["button-data-cy"]
                    : `${props.name}-input-box-suffix-button`
                }
                onClick={(event) => {
                  if (typeof props.onButtonClick === "function") {
                    props.onButtonClick(event);
                  }
                }}
                disabled={props.suffixButtonDisabled}
                className={`${props.buttonClassName} font-weight-normal margin-left-medium margin-right-medium padding-small font-color-secondary font-family-RHD-regular font-size-smaller background-transparent margin-left-8px suffix-button-border border-radius-0p7em text-transform-uppercase font-size-small `}
              >
                {props.buttonTile}
              </button>
            )}
          </div>
        </div>
      </div>
      <InputInfo />
    </div>
  );
}
export default PhoneNumberBox;
