import { useEffect, useState } from "react";
import SnackBar from "./Components/SnackBar/SnackBar";
import RaiseComplaint from "./Screens/RaiseComplaint/RaiseComplaint";
import { useAuthSubscriber } from "./Services/authentication";
import LogRocket from "logrocket";
import { logrocketInit } from "./utils/constants";
import { LanguageProvider } from "./Components/LanguageContext/LanguageContext";

function App(props) {
  const [status, setStatus] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);
  const [accessToken, setAccessToken] = useState(null);

  useAuthSubscriber((isAuthed) => {
    setIsAuthed(isAuthed);
  }, (onAccessToken)=>{
    setAccessToken(onAccessToken)
  });

  useEffect(() => {
    setStatus(null);
  }, [status]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key);
      }
    }
  }, []);

  return (
    <LanguageProvider>
      <div className="inherit-parent-height display-flex flex-direction-column flex-justify-content-center flex-align-items-center">
        <RaiseComplaint isAuthed={isAuthed} setStatus={setStatus} accessToken={accessToken} />
      </div>
      <SnackBar
        message={status?.message}
        status={status}
        type={status?.code === null ? "success" : "error"}
      />
    </LanguageProvider>
  );
}

export default App;
